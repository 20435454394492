import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
    container: {
        margin: '2rem auto',
        [theme.breakpoints.down('md')]: {
            margin: '1rem auto'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0.5rem 0.2rem',
            padding: 0
        }
    },
    paper: {
        padding: '4rem',
        [theme.breakpoints.down('md')]: {
            padding: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '2rem 1rem'
        }
    },
    title: {
        marginBottom: '2rem',
        textAlign: 'center'
    },
    subtitle: {
        marginTop: '1.5rem',
        marginBottom: '1rem'
    },
    list: {
        fontSize: '1rem'
    }
}));

const Politique = () => {
    const classes = useStyles();

    return (
        <Layout>
            <Container maxWidth='lg' className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography variant='h4' className={classes.title}>Terms and Conditions</Typography>
                    <Typography variant='subtitle2' gutterBottom><em>September 1, 2022</em></Typography>
                    <Typography gutterBottom>
                        Please note that by using this website (a “Site”), you accept these Terms. If you do not accept these Terms, do not use this Site. We may change these Terms from time to time, 
                        so you should review them each time that you visit the Site. You should print a copy of these Terms for future reference.
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>Index</Typography>
                    <ol className={classes.list}>
                        <li>About us</li>
                        <li>Using our Site</li>
                        <li>Third party content and links available on this Site</li>
                        <li>Your personal information</li>
                        <li>Our liability</li>
                        <li>Maintenance of the Site</li>
                        <li>Validity of these terms</li>
                        <li>Jurisdiction and applicable law</li>
                    </ol>
                    <Typography variant='h5' className={classes.subtitle}>1. About us</Typography>
                    <Typography gutterBottom>
                        This site, www.acutleaks.com, “Site”, is an online magazine that covers several everyday topics.
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>2. Using our site</Typography>
                    <Typography gutterBottom>
                        You may view (and, where applicable) listen to) the content available on the Site for personal non-commercial use. You may occasionally print individual webpages on the Site for your private non-commercial use, 
                        provided that such printing is not substantial or systematic and our trade marks and copyright and trade mark notices are not removed.
                    </Typography>
                    <Typography gutterBottom>
                        Unless otherwise stated in these Terms, you must not (whether directly or indirectly) copy, download, store, make available, distribute, sell or offer to sell all or any part of the content or Site, 
                        or download or otherwise copy (whether directly or indirectly) any content, files or data from the Site to make or populate a database or publication of any kind whatsoever. 
                        If you require any further information on permitted use, or a licence to re publish any part of the Site (or any Content), please email us at support@actuleaks.com
                    </Typography>
                    <Typography gutterBottom>
                        You may only play video or audio files using the media player on our Site.
                    </Typography>
                    <Typography gutterBottom>
                        If you would like a friend to read a story or watch or listen to a video or audio file on our Site, please you may email a link to our Site to your friend or simply ask them to visit our Site. 
                        Please ensure that you have their consent before giving us their details.
                    </Typography>
                    <Typography gutterBottom>
                        You must not use all or any part of our Site or the contents on it for commercial purposes without our permission.
                    </Typography>
                    <Typography gutterBottom>
                        Users, whether or not registered, must not abuse our Report Abuse facility e.g. by making malicious reports.
                    </Typography>
                    <Typography gutterBottom>
                        Information posted on www.actuleaks.com official social media accounts, including Twitter and Facebook, is often published just as breaking news occurs and may not be amended or removed following updates made to articles. 
                        Whilst every effort is made to ensure the accuracy of posts, for the most current version of a story, readers should visit www.actuleaks.com
                    </Typography>
                    <Typography gutterBottom>
                        You may use and display on your website or social networking profile page content on our Site where an embed code is provided (the “Embeddable Content”) subject to these Terms and on the following conditions:
                    </Typography>
                    <ul className={classes.list}>
                        <li>Any use of the Embeddable Content must be for personal, non-commercial purposes. You may not charge users of your website for access to the Embeddable Content, 
                            use the Content as means to secure advertising, or commercialise the Embeddable Content in any other way.</li>
                        <li>All intellectual property rights in and to the Embeddable Content, embed code and the embeddable player shall remain the property of us and/or our licensors.</li>
                        <li>The Embeddable Content cannot be used on any websites that:</li>
                        <ul>
                            <li>contain unlawful text or images; or</li>
                            <li>contain pornography or sexually explicit content; or</li>
                            <li>contain gratuitous violence; or</li>
                            <li>condone or encourage unlawful acts.</li>
                        </ul>
                        <li>You may only use the Embeddable Content where Your Site:</li>
                        <ul>
                            <li>complies with all applicable data protection and privacy laws, codes of practice and the self-regulatory principles for online behavioural advertising; and</li>
                            <li>clearly explains that cookies and similar tracking technologies may be used on Your Site by third parties for analytics and advertising purposes.</li>
                        </ul>
                        <li>We grant you a non-exclusive, non-transferable licence to use the embeddable computer code to display on Your Site the Content.</li>
                        <li>You may not copy, re-publish, edit, alter, add to or use the embed code or embeddable player in any other way.</li>
                        <li>All title, ownership rights and intellectual property rights in and to the Embeddable Content, the embed code and the embeddable player shall remain our property of the property of our licensors</li>
                        <li>You may not directly or indirectly suggest any endorsement or approval by us of your site or any entity, product or content or any views expressed within your site without our prior written approval.</li>
                        <li>You may not use the Embeddable Content in any way that could bring us into disrepute or otherwise cause any loss or damage to us.</li>
                        <li>The Embeddable Content is made available by us on an “as is” and “as available” basis and We give no warranty of any kind in relation to the Embeddable Content, embed code or the embeddable player, 
                            including warranties related to non-infringement of third party rights. We disclaim all implied and statutory warranties to the maximum extent permitted by law.</li>
                        <li>You hereby agree to reimburse Us in respect of all damages, costs and expenses, including reasonable legal fees and litigation expenses, 
                            arising out of or as a result of any breach of these Embedding Terms or otherwise in connection with your use of the Embeddable Content and/or the embeddable code and player</li>
                    </ul>
                    <Typography variant='h5' className={classes.subtitle}>3. Sponsored content, third party content and links available on this Site</Typography>
                    <Typography gutterBottom>
                        Where you see the label “Sponsored Content” on an article on this Site it means that our Publisher/Editor retains editorial control and sign off of the article. 
                        Where you see the label “Advertorial” these are also written by our journalists but control and final sign off lies with the advertiser.
                    </Typography>
                    <Typography gutterBottom>
                        We are not liable or responsible for the third party content on this Site. Third party content includes, for example, comments posted by users and the content of advertisements.
                    </Typography>
                    <Typography gutterBottom>
                        Where this Site contains links to other sites and resources, which are provided by third parties, these links and resources are provided for your information only and you access them at your own risk. 
                        We are not liable or responsible for the content of third party sites or resources.
                    </Typography>
                    <Typography>
                        If you see something which you reasonably believe breaches these Terms or our House Rules, please contact us support@actuleaks.com
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>4. Your personal information</Typography>
                    <Typography>
                        We will use your personal information in accordance with our privacy policy, which forms part of these Terms. Please read our Privacy Policy now.
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>5. Our liability</Typography>
                    <Typography gutterBottom>
                        The information contained on this Site is for information purposes only and does not constitute advice. You should check any information on the Site and use your own judgement 
                        before doing or not doing anything on the basis of what you see. We give no warranties of any kind in relation to the Site or its contents.
                    </Typography>
                    <Typography gutterBottom>
                        Except for liability for fraudulent misrepresentation, we are not liable for:
                    </Typography>
                    <ul className={classes.list}>
                        <li>any action you may take as a result of relying on any information provided on this Site or for any loss or damage suffered by you as a result of you taking this action;</li>
                        <li>any dealings you have with third parties (e.g. other users, advertisers or promoters) that take place using or facilitated by the Site;</li>
                        <li>any liability for losses which are not a foreseeable or likely consequence of (i) your use of the Site, or (ii) a breach of these Terms; and</li>
                        <li>any loss or damage which you may suffer as a result of or connected to your use of the embed code, player or the Embeddable Content.</li>
                    </ul>
                    <Typography gutterBottom>
                        We are not responsible if you cannot access the Site properly or at all because of any event outside our control, for example (without limitation) the performance of your or our ISP, your browser or the Internet.
                    </Typography>
                    <Typography gutterBottom>
                        The Site relies in part on software to work. Software has bugs. Whilst we will monitor the Site and try to fix bugs, 
                        we cannot guarantee that the Site or any individual feature of the Site will be error free, available all the time and/or free from viruses.
                    </Typography>
                    <Typography>
                        However, nothing in these Terms will affect any liability we may have for death or personal injury arising from our negligence or any other liability which cannot be excluded or limited by law.
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>6. Maintenance of the Site</Typography>
                    <Typography gutterBottom>
                        Making your use of the Site enjoyable means we need to fix bugs, install updates and do general diagnosis and maintenance of the Site. 
                        We will try to do scheduled maintenance during times when we anticipate that online use is lower than normal.
                    </Typography>
                    <Typography>
                        We also need to be able to do emergency maintenance and/or suspend access to the servers where, in our reasonable discretion, we see the need to do that. 
                        We will try to have the Site available again as soon as we think it is safe to do so.
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>7. Validity of these Terms</Typography>
                    <Typography>
                        If any part or provision of these Terms is found to be unlawful or unenforceable, this shall not affect the validity of any other part or provision.
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>8. Jurisdiction and applicable law</Typography>
                    <Typography>
                        These Terms are governed by English law. The English courts shall have exclusive jurisdiction over any dispute relating to these Terms.
                    </Typography>
                </Paper>
            </Container>
        </Layout>
    );
};

export default Politique;